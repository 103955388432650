import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { useAjaxContext } from '../../../core/ajax/contexts'
import { feedback } from '../../../core/feedback'

import { sanitizePayload } from '../../../core/utils/sanitizePayload'

export const useTariffCalls = () => {
  const { get, getById, post, putById, patchById } = useAjaxContext()
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getTariffNameUnicity = useCallback((name, id_empresa_ca, extra_search = {}) => {
    return new Promise((resolve, reject) => {
      get(`/tarifa/comprobarTituloDuplicado/${id_empresa_ca}`, {
        params: { titulo: name, ...extra_search },
      })
        .then((response) => resolve(response))
        .catch(({ response }) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            default:
              defaultError()
          }
          return reject(response.status)
        })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getTariff = useCallback(
    (tariffId) =>
      new Promise((resolve, reject) => {
        getById(`/tarifa/obtenerDatosTarifa`, `${tariffId}`)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 401:
              case 404:
              case 502:
                feedback(
                  'error',
                  formatMessage({ id: 'calls.tariffs.error.wrongTariff.description' })
                )
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getTariffZone = useCallback((empresaId, tariffId) => {
    const enterprise = parseInt(empresaId)
    if (isNaN(enterprise)) {
      throw new Error('Wrong petition!')
    }
    return new Promise((resolve, reject) => {
      getById(`/tarifa/zonastarifa/${enterprise}/tarifa`, `${tariffId}`)
        .then((response) => resolve(response))
        .catch(({ response }) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            case 401:
            case 404:
              feedback(
                'error',
                formatMessage({ id: 'calls.tariffs.error.wrongTariffZone.description' })
              )
              break
            default:
              defaultError()
          }
          return reject(response.status)
        })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const newTariff = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        post('/tarifa', payload)
          .then((response) => {
            resolve(response)
            return feedback(
              'success',
              formatMessage({ id: 'calls.tariffs.success.TariffCreated.description' })
            )
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400: //Bad request
              default:
                feedback(
                  'error',
                  formatMessage({ id: 'calls.tariffs.error.wrongPostTariff.description' })
                )
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putTariff = useCallback((tariffId, payload) => {
    return new Promise((resolve, reject) => {
      const cleanedPayload = sanitizePayload(payload);
  
      putById('/tarifa/modificar', tariffId, cleanedPayload)
        .then((response) => {
          resolve(response)
          return
          // return feedback( /*Owner's petition: don't show this message unless we stay in same page*/
          //   'success',
          //   formatMessage({ id: 'calls.tariffs.success.TariffSaved.description' })
          // )
        })
        .catch(({ response }) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            default:
              feedback(
                'error',
                formatMessage({ id: 'calls.tariffs.error.wrongPutTariff.description' })
              )
          }
          return reject(response.status)
        })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const patchTariff = useCallback((tariffId, payload) => {
    return new Promise((resolve, reject) => {
      patchById('/tarifa/modificar', tariffId, payload)
        .then((response) => {
          resolve(response)
          return //No feedback
        })
        .catch(({ response }) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            default:
              feedback(
                'error',
                formatMessage({ id: 'calls.tariffs.error.wrongPutTariff.description' })
              )
          }
          return reject(response.status)
        })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const putTariffZone = useCallback(
    (tariffId, tariffZone) =>
      new Promise((resolve, reject) => {
        putById('/tarifa/zonastarifa', tariffId, tariffZone)
          .then((response) => {
            resolve(response)
            return
            // return feedback(
            //   'success',
            //   formatMessage({ id: 'calls.tariffs.success.TariffZoneSaved.description' })
            // )
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                feedback(
                  'error',
                  formatMessage({ id: 'calls.tariffs.error.wrongPutTariffZone.description' })
                )
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getTariffNameUnicity,
    getTariff,
    getTariffZone,
    putTariff,
    patchTariff,
    putTariffZone,
    newTariff,
  }
}
